@import '../../Index.scss';

.main_space_bears {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  position: relative;
  bottom: 8rem;
  @include media("mobile"){
    bottom: 0;
  }
  .space_bears {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      padding-top: 7rem;
      .discordBtn{}
    }
    .first_bear {
      img {
        max-width: 20%;

        max-width: 254px;
        max-height: 496px;
        width: 100%;
        height: 100%;
        @include media('mobile') {
          max-width: 132px;
          max-height: 257px;
        }
      }
    }
    .second_bear {
      img {
        max-width: 20%;
        max-width: 254px;
        max-height: 496px;
        width: 100%;
        height: 1005;
        @include media('mobile') {
          max-width: 132px;
          max-height: 496px;
        }
      }
    }
  }
}
