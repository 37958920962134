@import '../../Index.scss';

.main_partners {
  width: 100%;
  height: 100%;
  margin-top: 10rem;
  @include media('mobile') {
    margin-top: 6rem;
  }
  .img_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 200px;
      @include media('mobile') {
        width: 114px;
        height: 114px;
      }
    }
  }
  .partners {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 4rem;
    @include media('mobile') {
      flex-direction: column;
      padding-top: 1rem;
    }
    .social_icons {
      @include media('mobile') {
        padding: 1rem;
      }
      img {
        margin: 0 0.5rem;
        @include media('mobile') {
          width: 38px;
          height: 38px;
        }
      }
    }
    .partners_icons {
      padding-right: 1.5rem;
      @include media('mobile') {
        padding: 0;
      }
      img {
        @include media('mobile') {
          width: 158px;
          height: 52px;
        }
      }
    }
    .head {
      @include media('mobile') {
        padding-bottom: 0.5rem;
      }
      h3 {
        color: #ffffff;
        font-size: 22px;
        font-family: Lemons-Bold;
        @include media('mobile') {
          font-size: 18px;
          text-align: center;
        }
      }
    }
  }
}
