@import '../../Index.scss';

.main_works {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  @include media('mobile') {
    padding-bottom: 5rem;
  }
  .works_head {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media('mobile') {
      margin-top: 2rem;
    }
    h2 {
      color: #ffffff;
      font-size: 60px;
      letter-spacing: 0.135em;
      margin: 0 0 22px;
      text-transform: uppercase;
      font-family: Lemons-Bold;
      @include media('mobile') {
        font-size: 30px;
      }
      span {
        color: #3a8bae;
      }
    }
  }
  .joining {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('../../../public/Assets/overlaypanda.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 10rem 5rem;
    @include media('mobile') {
      padding: 0;
    }
    h1,
    p,
    button {
      color: #ffffff;
      position: relative;
      top: 4rem;
    }
    h1 {
      font-size: 60px;
      letter-spacing: 0.135em;
      margin: 0 0 22px;
      text-transform: uppercase;
      font-family: Lemons-Bold;
      @include media('mobile') {
        font-size: 30px;
        text-align: center;
      }
      span {
        color: #3a8bae;
      }
    }
    p {
      font-size: 18px;
      letter-spacing: 0.105em;
      line-height: 1.8em;
      margin: 0 0 22px;
      padding: 0 8rem;
      font-family: Lemons-Regular;
      font-weight: bold;
      text-align: center;
      @include media('mobile') {
        padding: 0 0.2rem;
        font-size: 11px;
        text-align: center;
      }
    }
    button {
      background: #fff;
      border-radius: 9px;
      border-bottom-right-radius: 0;
      color: #000;
      cursor: pointer;
      display: inline-block;
      font-family: Lemon\/Milk;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 0.175em;
      line-height: 1.09em;
      overflow: hidden;
      padding: 25px 70px;
      position: relative;
      text-align: center;
      transition: all 0.4s ease-in-out;
      vertical-align: middle;
      border: none;
      font-family: Lemons-Bold;
      span {
        position: relative;
        z-index: 99;
      }
    }
    button::after {
      border-bottom-left-radius: 100px;
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transition: all 0.4s ease-in-out;
      width: 130px;
      background: linear-gradient(90deg, #43b1df -29.23%, #0d5b95 93.85%);
    }
    button:hover::after {
      background: linear-gradient(90deg, #43b1df -29.23%, #0d5b95 93.85%);
      transition: all 0.3s ease-in-out;
      width: 40rem;
    }
    button:hover {
      color: #ffffff ;
    }
  }
}
