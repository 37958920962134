.main_Home_container{
    width: 100%;
    height: 7404;
    // height: 100%;
    background-image: url('../../../public/Assets/loneybg.jpg');
    // background-image: url('https://lonelybearsociety.com/static/media/NewRevistedwebitebackground.2f3358136ea6f228c81e.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: scroll;
    overflow: hidden;
}