@import '../../Index.scss';

.main_footer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .footer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 45px;
    padding: 16px 0 20px;
    position: relative;
    top: 20px;
    border-top: 1px solid hsla(0, 0%, 100%, 0.12);
    @include media('mobile') {
      margin-top: 0;
    }
    p {
      color: #c5d1da;
      font-size: 19px;
      font-weight: 400;
      letter-spacing: 0.46em;
      text-align: center;
      text-transform: uppercase;
      font-family: Lemons-Regular;
      @include media('mobile') {
        font-size: 10px;
        text-align: center;
      }
    }
  }
}
