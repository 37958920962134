.mainpartnersOverride {
  margin: 0;
}

.partnersOverride {
  padding: 0 12rem;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .mainpartnersOverride {
    margin-top: 10rem;
  }
}
