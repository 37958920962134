.main_join_page_home{
    width: 100%;
    // height: 3510px;
    height: 100%;
    background-image: url('../../../public/Assets/joinbg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: scroll;
    overflow: hidden;
}